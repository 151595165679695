import {createMuiTheme, createStyles, Theme} from "@material-ui/core/styles";

export const drawerWidth = 220;

export const appTheme = createMuiTheme({
    palette: {
        type: "dark",
        background: {
            default: "#1d1d1d"
        },
        primary: {
            main: "#2699FB",
            contrastText: "white"
        },
        secondary: {
            main: "rgb(0,180, 0)",
        },
        action: {
            disabled: "white",
            disabledBackground: "rgb(54,54,54)"
        }
    },
    overrides: {
        MuiButton: {
            root: {
                width: "100%",
                height: "48px"
            }
        },
        MuiSnackbarContent: {
            root: {
                '&[class*="variantSuccess"]': {
                    backgroundColor: 'rgb(69,179,69)',
                    textColor: "white",
                    color: "white",
                    fontWeight: 600,
                },
                '&[class*="variantError"]': {
                    backgroundColor: 'rgb(206,72,45)',
                    textColor: "white",
                    color: "white",
                    fontWeight: 600,
                },
                '&[class*="variantInfo"]': {
                    backgroundColor: 'rgb(255,255,255)',
                    textColor: "white",
                    color: "black",
                    fontWeight: 600,
                },
            }
        },
    }
});

export const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexGrow: 1,
    },
});
