import React, {Component} from 'react';
import './App.css';
import "./Spinner.css";
import {DMSMessageFactory, ICallable, IVersion, RCSlaveController} from "dms_commons";
import IStateMessage from "../../interfaces/IStateMessage";
import {IABDRCState} from "../../interfaces/IABDRCState";
import Slider, {Settings as CarouselSettings} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Stepper from "../Stepper";
import {ABDState} from "../../classes/ABDState";
import {IStartupDataMessage} from "../../interfaces/IStartupDataMessage";
import BarcodeScanner from "../BarcodeScanner";
import {SnackbarOrigin, VariantType, withSnackbar, WithSnackbarProps,} from 'notistack';
import {Button, CircularProgress, CssBaseline, withStyles, WithStyles} from "@material-ui/core";
import {styles} from "../../Styles";
import {Strings} from "../../Strings";
import PassportScanner from "../PassportScanner";
import TrackingManager, {TrackableEvents, TrackableScreens} from "../../classes/TrackingManager";

interface IABDStateRender {
    title?: string,
    subtitle?: string,
    element?: any,
    footerElement?: any,
    stepperHidden?: boolean,
    steps?: number,
    currentStep?: number
}

interface IMRZScanResult {
    confidence: number;
    timeElapsed: number;
    birth_date: string;
    country: string;
    doc: string;
    doc_number: string;
    expiry_date: string;
    final_hash: string;
    given_name_0: string;
    hash: string;
    nationality: string;
    personal_number: string;
    sex: string;
    surname: string;
}

interface IState {
    isLoading: boolean;
    cameraAccessDenied: boolean;
    hasCameraAccess: boolean;
    pairingScanResult?: string;
    bagTagScanResult?: string;
    pairedWithMaster: boolean;
    abdStartupData?: IStartupDataMessage;
    abdState?: IStateMessage;
    abdWeight?: string;
    abdSelectedBag: number;
    abdWaitingForPassScan: boolean;
    abdMrzScanResult?: IMRZScanResult
    isGettingCameraStream: boolean;
    cameraStream?: MediaStream
}

interface IProps extends WithStyles, WithSnackbarProps {

}

const dmsHostname = "anon.bbcdms.app";
const dmsPortNumber = 443;
const dmsUseWss = true;

class App extends Component<IProps, IState> {
    state: IState = {
        isLoading: true,
        cameraAccessDenied: false,
        abdSelectedBag: 1,
        hasCameraAccess: false,
        pairedWithMaster: false,
        abdWaitingForPassScan: false,
        isGettingCameraStream: false
    }

    private appVersion: IVersion = {major: 1, minor: 1, build: 10};
    private rcSlaveController?: RCSlaveController;

    public async componentDidMount() {
        (window as any).connectToMaster = this.connectToMaster;
        (window as any).handleQRCodeScan = this.handleQRCodeScan;
        (window as any).sendABDButtonClick = this.sendABDButtonClick;

        TrackingManager.trackScreen(TrackableScreens.WAITING_FOR_PAIRING);

        try {
            setTimeout(() => {
                this.setState({
                    isLoading: false
                })
            }, 1500);

            const masterId = this.parseQuery(window.location.href, "masterId");

            if (!masterId) {
                throw new Error("no master id");
            }

            this.connectToMaster(masterId);
        } catch (err) {
            console.error("could not parse masterId: " + err);
        }
    }

    public render() {
        const abdStateRender = this.renderABDState();

        const currentLocale = this.state.abdState?.locale ?? "en";

        //return <BarcodeScanner requiredQrDataLength={10} minNumberOfSamples={2} onDetected={(x)=>{alert(x)}} onError={()=>{alert("y")}} mode={"barcode"}/>

        return (
            <div style={{height: "100%"}}>
                <CssBaseline/>
                <div className={this.state.isLoading ? "launch-screen" : "launch-screen finished"}>
                    <div className={"loader-wrapper"}>
                        <div className={"loader"}/>
                    </div>
                    <img alt={"bbc"} className="bbc-logo-main" src={"/resources/bbclogo.png"}/>
                    <div className={"launch-screen-footer"}>
                        <h1>Handymat</h1>
                        <h5 style={{margin: 8}}>{`${this.appVersion.major}.${this.appVersion.minor}.${this.appVersion.build}`}</h5>
                    </div>
                </div>
                {
                    this.state.isLoading ? undefined :
                        (<div className={"app"} style={{height: "100%"}}>
                            <header className="header">
                                <div className="header-buttons">
                                    <img alt={"bbc"} className="bbc-logo" src={"/resources/bbclogo.png"}/>
                                    <div
                                        style={{
                                            opacity: this.state.pairedWithMaster ? 1 : 0,
                                            transition: "all 250ms ease-in-out"
                                        }}>
                                        <img
                                            alt={"language"}
                                            className="lang-flag"
                                            src={"/resources/flags/" + currentLocale + ".svg"}
                                            height="87"
                                            width="100"/>
                                    </div>
                                </div>
                                <Stepper style={{opacity: abdStateRender.stepperHidden ? 0 : 1}}
                                         steps={abdStateRender.steps ?? 0}
                                         current={abdStateRender.currentStep ?? 0}/>
                                <div className={"header-title"}>
                                    <h1>{abdStateRender.title}</h1>
                                    <p>{abdStateRender.subtitle}</p>
                                </div>
                            </header>
                            <div className={"wrapper"}>
                                {abdStateRender.element}
                            </div>
                            <footer
                                className={abdStateRender.footerElement ? "footer" : "footer collapsed"}>{abdStateRender.footerElement}</footer>
                        </div>)
                }
            </div>
        );
    }

    private getBagTypeSliderSettings = () => {
        const self = this;

        const selectBagTypeSliderSettings: CarouselSettings = {
            centerMode: true,
            slidesToShow: 1,
            initialSlide: 1,
            arrows: false,
            infinite: false,
            centerPadding: "0px",
            variableWidth: true,
            easing: "easeOutElastic",
            adaptiveHeight: true,
            autoplay: false,
            afterChange(currentSlide: number) {
                self.setState({
                    abdSelectedBag: currentSlide
                })
            },
            responsive: [
                {
                    breakpoint: 0,
                    settings: {
                        dots: true
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        dots: true
                    }
                },
                {
                    breakpoint: 451,
                    settings: {
                        dots: false
                    }
                }
            ]
        }

        return selectBagTypeSliderSettings;
    }

    private renderABDState = (): IABDStateRender => {
        if (!this.state.hasCameraAccess) {
            return {
                title: Strings.camAccessTitle,
                subtitle: Strings.camAccessSubtitle,
                element: (<div className="drawer-container">
                    <div className="drawer">
                        <img className="drawer-icon" alt="camera" src={"/resources/camera_icon.png"}/>
                        <p className="drawer-title">
                            {
                                this.state.cameraAccessDenied ? Strings.camAccessDeniedTitle : undefined
                            }
                        </p>
                        <p className="drawer-text">
                            {this.state.cameraAccessDenied ? Strings.camAccessDeniedSubtitle : Strings.camAccessInstructions}
                        </p>
                        <div className="drawer-footer">
                            <Button
                                disabled={this.state.isGettingCameraStream || Boolean(this.state.cameraStream)}
                                variant="contained"
                                size="large"
                                color={"primary"}
                                disableElevation
                                onClick={async () => {
                                    if (this.state.isGettingCameraStream || this.state.cameraStream) {
                                        return;
                                    }

                                    await this.requestCameraAccess();
                                }}>
                                {
                                    Strings.enableCamera
                                }
                                {this.state.isGettingCameraStream ?
                                    <CircularProgress size={20} style={{marginLeft: 5}}/> : undefined}
                            </Button>
                        </div>
                    </div>
                </div>)
            }
        }

        if (!this.state.pairingScanResult && !this.state.pairedWithMaster) {
            return {
                title: Strings.scanQRCode,
                currentStep: 0,
                steps: 5,
                element: <BarcodeScanner
                    mode="qr"
                    cameraStream={this.state.cameraStream!}
                    onError={(err) => {
                        this.displaySnackbar(err.toString(), "warning", {
                            vertical: "top",
                            horizontal: "center"
                        });

                        TrackingManager.trackEvent(TrackableEvents.ERROR, "camera", err.toString());
                    }
                    }
                    onDetected={(data) => {
                        this.handleQRCodeScan(data);
                    }}
                />

            }
        }

        let abdStateRender: IABDStateRender = {};

        if (this.state.abdWaitingForPassScan) {
            abdStateRender.title = Strings.scanPassport;
            abdStateRender.subtitle = Strings.scanPassportDescription;
            abdStateRender.element = <PassportScanner/>;
            abdStateRender.footerElement =
                <div style={{display: "flex", flexDirection: "row"}}>
                    <Button style={{margin: "0px 4px"}} size={"small"}
                            variant={"contained"} color={"default"}
                            onClick={() => {
                                this.setState({
                                    abdWaitingForPassScan: false
                                })
                            }}>{Strings.skip}</Button>
                </div>
        } else if (this.state.abdState) {
            abdStateRender.title = this.state.abdState!.headline;
            abdStateRender.subtitle = this.state.abdState!.text1;
            abdStateRender.steps = this.state.abdStartupData?.showBagSelect ? 6 : 5;
            abdStateRender.currentStep = this.state.abdState!.progress;
            abdStateRender.element = <h1>{"Unhandled State: " + this.state.abdState!.state}</h1>

            switch (this.state.abdState!.state) {
                case ABDState.CHOOSING_BAG_TYPE:
                    abdStateRender.element = (<Slider className={"sc"} {...this.getBagTypeSliderSettings()}>
                        <img alt={"bag"}
                             src={"/resources/select-bag.png"}/>

                        <img alt={"suitcase"}
                             src={"/resources/select-suitcase.png"}/>


                        <img alt={"backpack"}
                             src={"/resources/select-backpack.png"}/>

                    </Slider>);
                    abdStateRender.footerElement =
                        <Button size="large" variant="contained" color={"primary"} disableElevation onClick={() => {
                            this.sendABDButtonClick("BAG_TYPE_SELECT", this.state.abdSelectedBag.toString())
                            TrackingManager.trackScreen(TrackableScreens.BAG_SELECTED);
                        }}>
                            {Strings.continue}
                        </Button>;
                    break;
                case ABDState.DATA_FOR_VERIFICATION_READY_TO_BE_READ:
                    // TODO
                    break;
                case ABDState.CHOOSE_AIRLINE:
                    abdStateRender.title = Strings.somethingWentWrong;
                    abdStateRender.element = (<div>
                        <img alt={"Out Of Order"}
                             src={"/resources/out-of-order.png"}/>
                    </div>)
                    break;
                case ABDState.BAG_SEND_FAILURE:
                case ABDState.REVERSING_BAG:
                case ABDState.BAG_WAS_REVERSED:
                case ABDState.BAG_REJECTED_AFTER_SCALE:
                case ABDState.BAG_SEND_FAILURE_AND_BUFFERED_BAGS:
                case ABDState.TRAY_NOT_AVAILABLE:
                case ABDState.CHECKIN_CANCELLED:
                    abdStateRender.element = <div className={"loader-container"}>
                        <div className="circle-loader load-error">
                            <div className="checkmark error"/>
                        </div>
                    </div>
                    break;
                case ABDState.TRAY_REQUESTED:
                case ABDState.DELIVERING_TRAY:
                    abdStateRender.element = <div>
                        <img alt={"tray"}
                             src={"/resources/delivering_tray.gif"}/>
                    </div>
                    break;
                case ABDState.READY_FOR_NEXT_BAG_IN_GROUP:
                case ABDState.BAG_READY_TO_BE_SCALED:
                    abdStateRender.element = (<div>
                        {
                            this.state.abdState!.bagType === "0" ? (
                                <img className={"selected-bag-type-img"} alt={"bag"}
                                     src={"/resources/scale-bag.png"}/>) : undefined
                        }
                        {
                            this.state.abdState!.bagType === "1" ? (
                                <img className={"selected-bag-type-img"} alt={"suitcase"}
                                     src={this.state.abdStartupData?.suitcaseStanding ? "/resources/scale-suitcase-standing.png" : "/resources/scale-suitcase.png"}/>) : undefined
                        }
                        {
                            this.state.abdState!.bagType === "2" ? (
                                <img className={"selected-bag-type-img"} alt={"backpack"}
                                     src={"/resources/scale-backpack.png"}/>) : undefined
                        }
                    </div>);
                    break;
                case ABDState.TAG_READY_TO_BE_SCANNED:
                    abdStateRender.element =
                        <BarcodeScanner
                            requiredQrDataLength={10}
                            minNumberOfSamples={2}
                            mode="barcode"
                            onError={(err) => {
                                this.displaySnackbar(err.toString(), "warning", {
                                    vertical: "top",
                                    horizontal: "center"
                                });

                                TrackingManager.trackEvent(TrackableEvents.ERROR, "camera", err.toString());
                            }}
                            onDetected={(data) => {
                                if (data && data !== this.state.bagTagScanResult) {
                                    this.setState({
                                        bagTagScanResult: data
                                    });

                                    TrackingManager.trackScreen(TrackableScreens.BAGTAG_SCANNED);

                                    this.sendABDButtonClick("TAG_SCAN", data);
                                }
                            }}/>
                    abdStateRender.footerElement = (<
                        div>
                        < div
                            className={"weight-indicator"}>
                            < img
                                alt={"weight"}
                                style={
                                    {
                                        height: 22, width: 27
                                    }
                                }
                                src={"/resources/weight_icon.png"}
                            />
                            <p className={"weight-value"}>{this.state.abdWeight ?? 0}</p>
                            <p>kg</p>
                        </div>
                    </div>)
                    break;
                case ABDState.CHECKING_BAG_DATA:
                    abdStateRender.element = <div className={"loader-container"}>
                        <img className={"airline-logo"}
                             src={"/resources/airlines/" + this.state.abdState?.airline + ".png"} alt={"airline"}/>
                        <div className="circle-loader">
                        </div>
                    </div>
                    break;
                case ABDState.SENDING_BAG:
                    abdStateRender.element = <div className={"loader-container"}>
                        <img className={"airline-logo"}
                             src={"/resources/airlines/" + this.state.abdState?.airline + ".png"} alt={"airline"}/>
                        <p className={"rounded-outline"}>{this.state.abdState?.pax}
                            <img style={{height: 14}} alt={"icon"} src={"/resources/passenger-icon.svg"}/>
                        </p>
                        <p style={{marginBottom: 32}}
                           className={"rounded-outline"}>{this.state.abdState?.flight}
                            <img style={{height: 17}} alt={"icon"} src={"/resources/flight-icon.svg"}/>
                        </p>
                        <div className="circle-loader">

                        </div>
                    </div>
                    break;
                case ABDState.BAG_SENT:
                    abdStateRender.element = <div className={"loader-container"}>
                        <div className="circle-loader load-complete">
                            <div className="checkmark draw"/>
                        </div>
                    </div>
                    break;
                case ABDState.OUT_OF_ORDER:
                    abdStateRender.element = (<div>
                        <img alt={"Out Of Order"}
                             src={"/resources/out-of-order.png"}/>
                    </div>)
                    break;
                case ABDState.REMOVING_BAG_BEFORE_TRAY_DELIVERY:
                    abdStateRender.element = (<div>
                        {
                            this.state.abdState!.bagType === "0" ? (
                                <img className={"selected-bag-type-img"} alt={"bag"}
                                     src={"/resources/remove-bag.gif"}/>) : undefined
                        }
                        {
                            this.state.abdState!.bagType === "2" ? (
                                <img className={"selected-bag-type-img"} alt={"backpack"}
                                     src={"/resources/remove-backpack.gif"}/>) : undefined
                        }
                    </div>)
                    break;
                case ABDState.BAG_SENT_AND_CONFIRM_NEXT_BAG:
                    abdStateRender.element = <div>
                        <img src={"/resources/morebags.png"} alt={"More Bags?"}/>
                    </div>;
                    abdStateRender.footerElement =
                        <div style={{display: "flex", flexDirection: "row", width: "100%", margin: 8}}>
                            <Button size="large" onClick={() => {
                                this.sendABDButtonClick("NEXT_BAG", "true");
                            }
                            } variant={"contained"} color={"primary"} style={{margin: 8}}>{Strings.yes}</Button>
                            <Button size="large" onClick={() => {
                                this.sendABDButtonClick("NEXT_BAG", "false");
                            }
                            } variant={"contained"} style={{margin: 8}}>{Strings.no}</Button>
                        </div>
                    break;
            }
        } else {
            return {
                title: Strings.scanQRCode,
                currentStep: 0,
                steps: 5,
                element: <div className="circle-loader">
                </div>
            }
        }

        return abdStateRender;
    }

    private canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (blob instanceof Blob) {
                    resolve(blob);
                } else {
                    reject(new Error("failed to get blob"))
                }
            }, "image/jpeg", 0.95)
        })
    }

    private requestCameraAccess = async () => {
        try {
            if (this.state.isGettingCameraStream || this.state.cameraStream) {
                return;
            }

            this.setState({
                isGettingCameraStream: true
            })

            const availableDevices = await navigator.mediaDevices.enumerateDevices();

            if (availableDevices.length < 1) {
                this.displaySnackbar(Strings.noCamera, "error");
                return;
            }

            const rearCameraStream = await BarcodeScanner.getDefaultRearCameraStream()

            this.setState({
                cameraStream: rearCameraStream
            }, () => {
                this.setState({
                    hasCameraAccess: true,
                })
            })
        } catch (error) {
            this.setState({cameraAccessDenied: true});

            TrackingManager.trackScreen(TrackableScreens.CAMERA_ACCESS_DENIED);

            if (error.name === "NotAllowedError") {
                this.displaySnackbar(Strings.noAccessToCamera, "error");
            } else {
                this.displaySnackbar(Strings.noAccessToCamera, "error");
            }
        } finally {
            this.setState({
                isGettingCameraStream: false
            })
        }
    }

    private connectToMaster = async (masterId: string) => {
        this.rcSlaveController = new RCSlaveController(dmsHostname, dmsPortNumber, dmsUseWss);

        console.log("connecting to master: ", masterId);

        const callables = {
            "ABD_MASTER_STATE_CHANGED": {
                parameters: [],
                implementation: (message) => {
                    console.log("master sent state: " + message.payload?.state);
                    this.onABDStateChanged(message.payload!);
                }
            } as ICallable<IABDRCState, void>
        };

        try {
            await this.rcSlaveController.start(masterId, {
                onPairedWithMaster: masterId1 => {
                    console.log("paired with master: " + masterId1)

                    TrackingManager.trackScreen(TrackableScreens.PAIRED);

                    this.setState({
                        pairedWithMaster: true,
                    }, () => {
                        this.displaySnackbar(Strings.connectedToBagomat, "success", {
                            vertical: "top",
                            horizontal: "center"
                        });
                    });

                    this.syncClientLanguageToMaster();
                },
                onMasterDisconnected: masterId1 => {
                    console.log("master " + masterId1 + " disconnected");
                    this.setState({
                        abdState: undefined,
                        pairedWithMaster: false,
                        abdMrzScanResult: undefined,
                    });
                },
                onDisconnected: error => {
                    console.log("disconnected from dms: ", error);
                    this.setState({
                        abdState: undefined,
                        pairedWithMaster: false,
                        abdMrzScanResult: undefined,
                    });
                }
            }, callables);
        } catch (error) {
            console.error("could not connect to master, reason: ", error);
            TrackingManager.trackEvent(TrackableEvents.ERROR, "dms", error.toString());
        }
    }

    private syncClientLanguageToMaster() {
        const clientLanguage = navigator.language.toLowerCase();

        let targetMasterLanguage = "en";

        const idx = clientLanguage.indexOf("-");

        if (idx > -1) {
            targetMasterLanguage = clientLanguage.substr(0, idx);
        } else {
            targetMasterLanguage = clientLanguage;
        }

        if (targetMasterLanguage === "nb" || targetMasterLanguage === "nn") {
            targetMasterLanguage = "no";
        }

        this.sendABDButtonClick("SET_LANGUAGE", targetMasterLanguage);
    }

    private displaySnackbar = (message: string, variant: VariantType = "info", anchorOrigin: SnackbarOrigin | undefined = undefined) => {
        this.props.enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: anchorOrigin ?? {vertical: "bottom", horizontal: "center"}
        });
    }

    private sendABDButtonClick = (button: string, info: string = "") => {
        const buttonClickNotification = DMSMessageFactory.newNotification("sendButtonClick", {button, info});
        this.rcSlaveController?.sendToMaster(buttonClickNotification, false);
    }

    private onABDStateChanged = (abdState: IABDRCState) => {
        console.log("onABDStateChanged", abdState);
        if (abdState.header === "STARTUP") {
            const abdStartupData = abdState.state as IStartupDataMessage;
            const passScanRequired = abdStartupData.handymatScanPassport;
            this.setState({abdStartupData, abdWaitingForPassScan: passScanRequired})
        } else if (abdState.header === "STATE") {
            this.setState({
                abdState: abdState.state as IStateMessage
            });

            const newABDState = (abdState.state as IStateMessage).state;
            switch (newABDState) {
                case ABDState.BAG_READY_TO_BE_SCALED:
                    this.setState({
                        bagTagScanResult: undefined
                    })
                    break;
                case ABDState.AUTHENTICATING:
                case ABDState.MANUAL_MODE:
                case ABDState.INITIALIZING:
                case ABDState.SHUTDOWN:
                case ABDState.REBOOT:
                case ABDState.WAITING_FOR_POSITION_READY:
                case ABDState.BAG_SENT:
                    if (newABDState === ABDState.BAG_SENT) {
                        (abdState.state as IStateMessage).text1 = Strings.canClosePage;
                        this.forceUpdate();
                        console.log("successfully sent bag, disconnecting from dms");
                        TrackingManager.trackScreen(TrackableScreens.FINISHED);
                    } else {
                        console.log("disconnecting because of state: " + newABDState);
                    }
                    this.rcSlaveController?.disconnect();
                    break;
            }

        } else if (abdState.header === "WEIGHT") {
            this.setState({
                abdWeight: (abdState.state as any).weight
            })
        } else if (abdState.header === "ERROR") {
            TrackingManager.trackScreen(TrackableScreens.ERROR);
            TrackingManager.trackEvent(TrackableEvents.ERROR, "app", abdState.toString());
            // setError(msg);
        } else if (abdState.header === "FAILING_SERVICES") {
            // setFailingServices(msg);
        } else {
            console.log("Received unknown header:" + abdState.header)
        }
    }

    private handleQRCodeScan = async data => {
        if (data && this.state.pairingScanResult !== data.toString()) {

            this.setState({
                pairingScanResult: data.toString()
            }, async () => {
                try {
                    const scannedMasterId = this.parseQuery(data.toString(), "masterId");

                    if (scannedMasterId) {
                        console.log(`scanned: ${scannedMasterId}`)
                        await this.connectToMaster(scannedMasterId);
                    }
                } catch (e) {
                    console.log(`failed to parse: ${e}`);
                    this.displaySnackbar(Strings.cantReadQR, "error");
                    TrackingManager.trackEvent(TrackableEvents.ERROR, "camera", e.toString());
                } finally {
                    this.setState({pairingScanResult: undefined});
                }
            })
        }
    }

    private parseQuery = (url: string, key: string) => {
        let params = (new URL(url)).searchParams;
        return params.get(key);
    }
}

export default withStyles(styles, {withTheme: true})(withSnackbar(App));
