import React, {Component, CSSProperties} from "react";

interface IState {

}

interface IProps {
    steps: number;
    current: number;
    style: CSSProperties;
}

export default class Stepper extends Component<IProps, IState> {
    public render = () => {
        return (<div style={this.props.style} className="stepper-container">
            {
                [...Array(this.props.steps)].map((e, i) => {
                    const isInactive = i > this.props.current;
                    return (<div key={i.toString()}
                                 className={isInactive ? "stepper-segment inactive" : "stepper-segment"}/>)
                })
            }
        </div>);
    }
}