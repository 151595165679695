import React, {Component} from 'react';
import {
    BrowserQRCodeReader,
    BrowserCodeReader,
    DecodeHintType,
    BarcodeFormat, BrowserMultiFormatReader
} from '@zxing/library';

interface IProps {
    onDetected: (value: string) => void;
    onError: (error: Error) => void;
    mode: "qr" | "barcode";
    minNumberOfSamples?: number;
    requiredQrDataLength?: number;
    cameraStream?: MediaStream
}

interface IState {
    samplesTaken: number;
    previousSample?: string;
}

export default class BarcodeScanner extends Component<IProps, IState> {
    state: IState = {
        samplesTaken: 0,
    }

    private reader?: BrowserCodeReader;

    public static is_iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
                'MacIntel'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    public static getDefaultRearCameraStream = async (): Promise<MediaStream | undefined> => {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();

        const getDefaultConstraints = (): MediaStreamConstraints => {
            return {
                audio: !BarcodeScanner.is_iOS(),
                video: {
                    facingMode: "environment"
                }
            };
        }

        const videoDevices = mediaDevices.filter(dev => {
            return dev.kind === "videoinput";
        });

        for (const dev of videoDevices) {
            try {
                const mediaConstraints = getDefaultConstraints();
                // @ts-ignore
                mediaConstraints.video!.deviceId = dev.deviceId;

                const userMedia = await navigator.mediaDevices.getUserMedia(mediaConstraints);
                const [track] = userMedia.getVideoTracks();
                const trackCapabilities = track.getCapabilities();

                if (trackCapabilities.facingMode && trackCapabilities.facingMode[0] === "environment") {
                    const focusDistance = trackCapabilities["focusDistance"];
                    if (focusDistance?.max <= 5) {
                        return userMedia;
                    }
                }

                track.stop();
            } catch (e) {
                console.warn("failed to get video stream: ", e);
            }
        }

        const mediaConstraints = getDefaultConstraints();

        // failed to manually find the rear camera
        return await navigator.mediaDevices.getUserMedia(mediaConstraints);
    }

    public async componentDidMount() {
        try {
            const previousSample = this.state.previousSample;
            const minNumberOfSamples = this.props.minNumberOfSamples;
            const requiredQrDataLength = this.props.requiredQrDataLength;

            let {cameraStream} = this.props;

            if (!cameraStream) {
                cameraStream = await BarcodeScanner.getDefaultRearCameraStream();
            }

            if (!cameraStream) {
                throw new Error("no rear camera stream available");
            }

            if (this.props.mode === "qr") {
                this.reader = new BrowserQRCodeReader();
            } else if (this.props.mode === "barcode") {
                const decoderHintMap = new Map<DecodeHintType, any>();
                decoderHintMap.set(DecodeHintType.TRY_HARDER, true);
                decoderHintMap.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.ITF]);

                this.reader = new BrowserMultiFormatReader(decoderHintMap);
                this.reader.hints = decoderHintMap;
            } else {
                throw new Error("unsupported mode " + this.props.mode)
            }

            const [track] = cameraStream.getVideoTracks();

            const trackSettings = track.getSettings();

            if (trackSettings["zoom"]) {
                // @ts-ignore
                await track.applyConstraints({advanced: [{zoom: 2}]});
            }

            const video = (document.getElementById("camera_renderer")! as any);

            await this.reader.decodeFromStream(cameraStream, video, (res) => {
                if (res) {
                    const scannedData = res.getText();

                    if (requiredQrDataLength && requiredQrDataLength !== scannedData.length) {
                        return;
                    }

                    if (minNumberOfSamples && this.state.samplesTaken < minNumberOfSamples) {
                        if (previousSample && previousSample !== res.getText()) {
                            this.setState({samplesTaken: 0, previousSample: res.getText()});
                            console.log(`sample mismatch: prevSample: ${previousSample}, currSample: ${scannedData}, resetting`);
                            return;
                        } else {
                            this.setState({
                                samplesTaken: this.state.samplesTaken + 1,
                                previousSample: scannedData
                            }, () => {
                                console.log(`got a new sample, total samples: ${this.state.samplesTaken}`);
                            });
                            return;
                        }
                    }

                    console.log("sampling ready");
                    this.setState({samplesTaken: 0, previousSample: undefined});
                    this.props.onDetected(scannedData);
                }
            })

        } catch (err) {
            if (this.props.onError) {
                this.props.onError(err);
            }
            console.error(err);
        }
    }

    public componentWillUnmount() {
        this.reader?.stopAsyncDecode();
        this.reader?.stopContinuousDecode();

        this.reader = undefined;
    }

    public render() {
        return (
            <div style={{
                overflow: "hidden",
                position: "relative",
                display: "grid",
                height: "100%",
                width: "95%",
                margin: "16px",
                borderRadius: "8px",
                animation: "fadein ease-in-out 500ms"
            }}>
                <video autoPlay={true} id="camera_renderer"
                       controls={false}
                       muted={true}
                       playsInline
                       style={{pointerEvents: "none", objectFit: "cover", height: "100%", width: "100%"}}/>
                <div id={"camera_renderer_overlay"} style={{}}/>
            </div>)
    }
}
