import LocalizedStrings from 'react-localization';

export let Strings = new LocalizedStrings({
    en: {
        noCamera: "You device does not have a camera.",
        noAccessToCamera: "Could not access the camera on your device",
        cantReadQR: "Could not read the QR code, please try again",
        somethingWentWrong: "Something went wrong",
        canClosePage: "You may now close this page",
        camAccessTitle: "We need to access your camera to continue",
        camAccessSubtitle: "Please follow the instructions below",
        scanQRCode: "Scan the QR Code on the Bagomat™",
        connectedToBagomat: "Connected to Bagomat™",
        continue: "Continue",
        enableCamera: "Enable Camera",
        yes: "Yes",
        no: "No",
        camAccessInstructions: "We need access to your camera in order to connect to the Bagomat™ and scan the bag tag on your bag.",
        camAccessDeniedTitle: "Camera permission denied",
        camAccessDeniedSubtitle: "You have blocked camera access. To continue, please update your browser settings to allow access.",
        skip: "Skip",
        scanPassport: "Please scan your passport",
        scanPassportDescription: "Place the passport in the frame",
        passportNotDetected: "The passport could not be detected, please try again.",
        passportScanned: "The passport was scanned successfully"
    },
    nb: {
        noCamera: "Enheten din mangler et kamera.",
        noAccessToCamera: "Har ikke tilgang til kameraet på din enhet",
        cantReadQR: "Kunne ikke lese QR koden, vennligst prøv igjen",
        somethingWentWrong: "Noe gikk galt",
        canClosePage: "Du kan nå lukke denne siden",
        camAccessTitle: "Vi må ha tilgang til kameraet ditt for å fortsette",
        camAccessSubtitle: "Vennligst følg instruksjonene",
        scanQRCode: "Skann QR koden på skjermen til Bagomat™",
        connectedToBagomat: "Koblet til Bagomat™",
        continue: "Fortsett",
        enableCamera: "Skru på kameraet",
        yes: "Ja",
        no: "Nei",
        camAccessInstructions: "Vi må ha tilgang til kameraet ditt for å koble til Bagomat™ og skanne din bagasjelapp.",
        camAccessDeniedTitle: "Kamera tilgang ble nektet",
        camAccessDeniedSubtitle: "Du har blokkert tilgang til kameraet ditt, for å fortsette vennligst oppdater instillingene til din nettleser.",
        skip: "Hopp over"
    },
    el: {
        noCamera: "Η συσκευή σας δεν έχει κάμερα",
        noAccessToCamera: "Δεν έγινε δυνατή χρήση της κάμερας στην συσκευή σας",
        cantReadQR: "Δεν έγινε ανάγνωση του κωδικού qr, δοκιμάστε ξανά",
        somethingWentWrong: "Παρουσιάστηκε κάποιο πρόβλημα",
        canClosePage: "Μπορείτε τώρα να κλείσετε αυτή την σελίδα",
        camAccessTitle: "Πρέπει να χρησιμοποιήσουμε την κάμερα για να συνεχίσουμε",
        camAccessSubtitle: "Ακολουθείστε της παρακάτω οδηγίες",
        scanQRCode: "Σαρώστε τον κωδικό QR στο Bagomat™",
        connectedToBagomat: "Σύνδεση με Bagomat™",
        continue: "ΣΥΝΕΧΕΙΑ",
        enableCamera: "ΕΝΕΡΓΟΠΟΙΗΣΤΕ ΤΗΝ ΚΑΜΕΡΑ",
        yes: "Ναι",
        no: "Όχι",
        camAccessInstructions: "Χρειάζεται πρόσβαση στην κάμερα για να γίνει σύνδεση με το Bagomat™ και σάρωση της ετικέτας αποσκευής",
        camAccessDeniedTitle: "Δεν έγινε δεκτή η πρόσβαση στην κάμερα",
        camAccessDeniedSubtitle: "Έχετε αρνηθεί την πρόσβαση στην κάμερα. Ανανεώστε τις ρυθμίσεις στο browser για να συνεχίσετε",
    }
});
