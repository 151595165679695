import React, {Component} from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    TextField,
    WithStyles,
    withStyles
} from "@material-ui/core";
import {FileCopy} from "@material-ui/icons";
import {styles} from "../../Styles";
import {withSnackbar, WithSnackbarProps} from "notistack";
import BarcodeScanner from "../BarcodeScanner";

interface IProps extends WithStyles, WithSnackbarProps {

}

interface IState {
    mediaDevices?: MediaDeviceInfo[];
    supportedConstraints?: MediaTrackSupportedConstraints;
    selectedDevice?: number;
    selectedStream?: MediaStream;
}

class Debug extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    private is_iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
                'MacIntel'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    async componentDidMount() {
        document.getElementById("root")!.classList.add("scrollable");

        const supportedConstraints = navigator.mediaDevices?.getSupportedConstraints() ?? {};

        let mediaDevices = await navigator.mediaDevices?.enumerateDevices() ?? [];
        mediaDevices = mediaDevices.filter(m => {
            return m.kind === "videoinput";
        });

        const rearCameraStream = await BarcodeScanner.getDefaultRearCameraStream();

        if (!rearCameraStream) {
            alert("failed to get the rear camera!");
            return;
        }

        this.setState({
            selectedStream: rearCameraStream,
            mediaDevices,
            supportedConstraints,
            selectedDevice: 0
        }, () => {
            const video = (document.getElementById("video")! as any);
            video.srcObject = rearCameraStream;
            video.play();
        });
    }

    private onCameraSelected = async (mediaDevice: MediaDeviceInfo) => {
        const mediaConstraints: MediaStreamConstraints = {
            audio: !this.is_iOS(),
            video: {
                deviceId: mediaDevice.deviceId
            }
        };

        if (this.state.selectedStream) {
            const videoTracks = this.state.selectedStream.getVideoTracks();
            videoTracks.forEach(vt => {
                vt.stop();
            })
        }

        const userMedia = await navigator.mediaDevices.getUserMedia(mediaConstraints);

        this.setState({
            selectedStream: userMedia
        }, () => {
            const video = (document.getElementById("video")! as any);
            video.srcObject = userMedia;
            video.play();
        });
    }

    public render() {
        const {mediaDevices, selectedStream} = this.state;

        const tracks = selectedStream?.getVideoTracks() ?? [];

        return <Container>
            <Grid container alignItems={"center"} alignContent={"center"} justify={"center"}
                  xs={12}>
                <Grid item xs={12} style={{marginTop: 16}}>
                    <Button onClick={async () => {
                        const rearCameraStream = await BarcodeScanner.getDefaultRearCameraStream();

                        if (!rearCameraStream) {
                            alert("failed to get the rear camera!");
                            return;
                        }

                        this.setState({
                            selectedStream: rearCameraStream
                        }, () => {
                            const video = (document.getElementById("video")! as any);
                            video.srcObject = rearCameraStream;
                            video.play();
                        });

                    }} color={"primary"} variant={"contained"}>Get Default Camera</Button>
                    <FormControl fullWidth>
                        <InputLabel>Select a camera</InputLabel>
                        <Select value={this.state.selectedDevice}>
                            {(mediaDevices ?? []).map((t, index) => {
                                return <MenuItem
                                    value={index}
                                    onClick={async () => {
                                        this.onCameraSelected(t);
                                    }
                                    }
                                    key={index}>{(t.label?.length > 0 ? t.label : "Video Device") + " - " + (index + 1)}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: 16
                }}>
                    <Slide
                        in={Boolean(selectedStream)}
                        unmountOnExit={true}>
                        <video
                            muted={true}
                            autoPlay
                            playsInline
                            style={{
                                height: 240,
                                objectFit: "contain",
                                borderRadius: 6
                            }}
                            id={"video"}/>
                    </Slide>
                </Grid>
                <Grid item xs={12}>
                    <Slide in={Boolean(selectedStream)}>
                        <div>
                            {tracks.map((t, i) => {
                                const data = {};
                                data["settings"] = t.getSettings();
                                data["capabilities"] = t.getCapabilities()
                                data["constraints"] = t.getConstraints();
                                data["videoTracks"] = (selectedStream?.getVideoTracks() ?? []).length;
                                data["userAgent"] = navigator.userAgent;

                                const serializedData = JSON.stringify(data, null, 3);

                                return <Card key={i}>
                                    <CardHeader
                                        title={t.label}
                                        action={<IconButton onClick={() => {
                                            navigator.clipboard.writeText(serializedData);

                                            this.props.enqueueSnackbar("Copied to clipboard", {
                                                variant: "default",
                                                anchorOrigin: {vertical: "bottom", horizontal: "center"}
                                            });
                                        }
                                        }>
                                            <FileCopy/>
                                        </IconButton>}>
                                    </CardHeader>
                                    <CardContent>
                                        <TextField
                                            fullWidth
                                            multiline={true}
                                            rows={25}
                                            value={serializedData}>

                                        </TextField>
                                    </CardContent>
                                </Card>
                            })}
                        </div>
                    </Slide>
                </Grid>
            </Grid>
        </Container>
    }
}


export default withStyles(styles, {withTheme: true})(withSnackbar(Debug));