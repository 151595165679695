export enum TrackableScreens {
    CAMERA_ACCESS_DENIED = "camera_access_deneid",
    WAITING_FOR_PAIRING = "waiting_for_pairing",
    BAG_SELECTED = "bag_selected",
    PAIRED = "paired",
    BAGTAG_SCANNED = "bagtag_scanned",
    FINISHED = "finished",
    ERROR = "error"
}

export enum TrackableEvents {
    ERROR = "error"
}

export default class TrackingManager {
    public static trackScreen = (page: TrackableScreens) => {
        try {
            // @ts-ignore
            gtag('event', 'page_view', {
                page_title: page,
                page_path: '/' + page,
                send_to: 'G-XLFPQ5QE1L'
            })
        } catch (e) {
            console.error("failed to track screen: " + e);
        }
    }

    public static trackEvent = (event: TrackableEvents, category: "camera" | "app" | "dms", value: string) => {
        try {
            // @ts-ignore
            gtag('event', event, {
                'event_category': category,
                'event_label': event,
                'value': value
            });
        } catch (e) {
            console.error("failed to track event: " + e);
        }
    }
}