import React, {Component} from 'react';
import {Button, CircularProgress, IconButton, Typography} from "@material-ui/core";
import {DeferredPromiseExecutor, Util} from "dms_commons";
import BugReportIcon from '@material-ui/icons/BugReport';

interface IMRZScanResult {
    confidence: number;
    timeElapsed: number;
    birth_date: string;
    country: string;
    doc: string;
    doc_number: string;
    expiry_date: string;
    final_hash: string;
    given_name_0: string;
    hash: string;
    nationality: string;
    personal_number: string;
    sex: string;
    surname: string;
}

interface IProps {
}

interface IState {
    isReady: boolean;
    progressText?: string;
    isStreaming: boolean;
    debugMode: boolean;
    scanResult?: IMRZScanResult;
}

export default class PassportScanner extends Component<IProps, IState> {
    state: IState = {
        isReady: false,
        isStreaming: false,
        debugMode: false
    }

    private static is_iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
                'MacIntel'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    private loadScript = (filename: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const scriptElement = document.getElementById(filename);

            if (!scriptElement) {
                const script = document.createElement('script');
                script.src = filename;
                script.id = filename;
                script.async = true;
                document.body.appendChild(script);

                script.onload = () => {
                    script.onload = null;
                    script.onerror = null;

                    resolve();
                };

                script.onerror = () => {
                    script.onload = null;
                    script.onerror = null;

                    reject();
                }
            }
        });
    }

    public async componentDidMount() {
        const debugModeEnabled = window.localStorage.getItem("debugMode") ?? "false";

        if (debugModeEnabled === "true") {
            this.setState({
                debugMode: true
            });
        }

        setTimeout(this.initialize, 0);
    }


    private mrzParserWorker?: Worker;
    private mrzParserDeferredPromiseExecutor = new DeferredPromiseExecutor();
    private tesseractWorker?: any;
    private canvasRoiOutput?: HTMLCanvasElement;

    private src: any;
    private cap: any;
    private USE_ADAPTIVE_THRESHOLDING = true;
    private ATTEMPT_HOUGH_ROTATE = false;

    private initialize = async () => {
        console.log("loading dependencies");

        this.setState({
            isReady: false,
            progressText: "Loading, please wait..."
        });

        try {
            const loadOpenCVPromise = this.loadScript("/docscan/opencv.js");
            const loadTesseractPromise = this.loadScript("/docscan/tesseract.min.js");
            const loadMrzParserWorkerPromise = this.initializeMrzParserWorker();

            await Promise.all([loadOpenCVPromise, loadTesseractPromise, loadMrzParserWorkerPromise]);
            await this.initializeTesseractWorker();

            let cv = (window as any).cv as any;

            if (cv.getBuildInformation) {
                //console.log(cv.getBuildInformation());
            } else {
                // WASM
                if (cv instanceof Promise) {
                    cv = await (window as any).cv;
                    // console.log(cv.getBuildInformation());
                } else {
                    cv['onRuntimeInitialized'] = () => {
                        //console.log(cv.getBuildInformation());
                    }
                }
            }

            console.log("finished loading dependencies")
        } catch (e) {
            alert("Failed to load dependencies, please try again.");
            return;
        }

        try {
            this.setState({
                progressText: "Waiting for camera..."
            })

            const stream = await navigator.mediaDevices.getUserMedia({
                audio: !PassportScanner.is_iOS(),
                video: {
                    facingMode: {ideal: "environment"},
                    sampleRate: {
                        min: 1280
                    },
                    width: {min: 640, ideal: 640},
                    height: {min: 480, ideal: 480},
                    channelCount: {min: 1}
                }
            });

            const videoEl = document.querySelector("video");
            videoEl!.srcObject = stream;

            videoEl!.addEventListener('canplay', () => {
                this.onVideoCanPlay(videoEl!)
            }, false);

            this.setState({
                progressText: undefined,
                isReady: true
            })
        } catch (error) {
            alert('navigator.MediaDevices.getUserMedia error: ' + error.message + " " + error.name);
        }
    }

    private onVideoCanPlay = (videoInput: HTMLVideoElement) => {
        videoInput.width = videoInput.videoWidth;
        videoInput.height = videoInput.videoHeight;

        console.log("onVideoCanPlay: ", videoInput.width, videoInput.height);

        this.canvasRoiOutput = document.getElementById("canvasRoi")! as HTMLCanvasElement;

        const cv = (window as any).cv as any;

        this.src = new cv.Mat(videoInput.height, videoInput.width, cv.CV_8UC4);
        this.cap = new cv.VideoCapture(videoInput);

        this.setState({
            isStreaming: true
        }, this.processVideo);
    }

    private processVideo = async () => {
        try {
            if (!this.state.isStreaming) {
                // clean and stop.
                this.src.delete();
                return;
            }

            this.cap.read(this.src);

            let roi = this.getMrzRoi(this.src);

            const cv = (window as any).cv;

            if (roi) {
                this.canvasRoiOutput!.height = roi.rows;
                this.canvasRoiOutput!.width = roi.cols;

                cv.imshow("canvasRoi", roi);

                if (this.state.debugMode) {
                    cv.imshow("canvasPreview3", roi);
                }

                let parsedMrzJson;

                try {
                    const res = await this.tesseractWorker.recognize(document.getElementById("canvasRoi"));

                    const scannedText = res.data.text.trim();

                    parsedMrzJson = await this.parseMrz(scannedText);
                    const parsedMrz = JSON.parse(parsedMrzJson);

                    if (parsedMrz && parsedMrz.nationality) {
                        this.setState({
                            scanResult: parsedMrz
                        });
                    }
                } catch (e) {
                    if (e) {
                        alert("Failed to parse: " + parsedMrzJson + "\nError: " + e.toString());
                    }
                }

                roi.delete();
            }

            // schedule the next one.
            requestAnimationFrame(this.processVideo);
        } catch (err) {
            alert(err);
        }
    }

    private rotateAdjust = (gray: any, largestContour: any, refImages: any[]) => {
        const cv = (window as any).cv;

        const target = gray.clone();

        // Find largest contour and surround in min area box
        const minAreaRect = cv.minAreaRect(largestContour)

        let angle = minAreaRect.angle;

        if (angle < -45) {
            angle = 90 + angle;
        }

        const w = target.cols;
        const h = target.rows;

        const dsize1 = new cv.Size(w, h);
        const center = new cv.Point(w / 2, h / 2)
        const M1 = cv.getRotationMatrix2D(center, angle, 1.0)

        cv.warpAffine(gray, target, M1, dsize1, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());
        refImages.forEach(refImage => {
            cv.warpAffine(refImage, refImage, M1, dsize1, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());
        });

        M1.delete();
        target.delete();
    }

    private getMrzRoi = (originalImage: any): any => {
        const cv = (window as any).cv;

        const IMG_HEIGHT = originalImage.rows; // 240;

        //resize the image to height=IMG_HEIGHT while maintaining the aspect ratio
        const resizedImage = originalImage.clone(); //resize(originalImage, IMG_HEIGHT);

        // convert to grayscale
        let gray = new cv.Mat();
        cv.cvtColor(resizedImage, gray, cv.COLOR_BGR2GRAY);

        // binarization
        // smooth the image using a 3x3 gaussian blur, then apply the blackhat
        // morphological operator to find dark regions on a light background
        cv.GaussianBlur(gray, gray, new cv.Size(3, 3), 0);

        // initialize a rectangular and square structuring kernel
        let rectKernel;
        let sqKernel;

        const rectX = IMG_HEIGHT / 46;
        const rectY = IMG_HEIGHT / 120;

        // used to be 34, but looks like /54 works much better for these dimensions
        const sqXY = IMG_HEIGHT / 54;

        rectKernel = cv.getStructuringElement(cv.MORPH_RECT, new cv.Size(rectX, rectY));
        sqKernel = cv.getStructuringElement(cv.MORPH_RECT, new cv.Size(sqXY, sqXY));

        cv.morphologyEx(gray, gray, cv.MORPH_BLACKHAT, rectKernel);
        cv.morphologyEx(gray, gray, cv.MORPH_CLOSE, rectKernel);

        if (this.USE_ADAPTIVE_THRESHOLDING) {
            let thresholdBlockSize = 11;
            cv.adaptiveThreshold(gray, gray, 255, cv.ADAPTIVE_THRESH_MEAN_C, cv.THRESH_BINARY_INV, thresholdBlockSize, 7);
        }

        cv.morphologyEx(gray, gray, cv.MORPH_CLOSE, sqKernel);

        let contours = new cv.MatVector();
        let hierarchy = new cv.Mat();
        cv.findContours(gray, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_NONE);

        rectKernel.delete();
        sqKernel.delete();

        let contoursArray = new Array<any>();

        for (let i = 0; i < contours.size(); i++) {
            contoursArray[i] = contours.get(i);
        }

        contoursArray.sort(function (a, b) {
            return cv.contourArea(b) - cv.contourArea(a);
        });

        if (this.ATTEMPT_HOUGH_ROTATE) {
            this.rotateAdjust(gray, contoursArray[0], [resizedImage, originalImage]);
        }

        if (this.state.debugMode) {
            cv.imshow("canvasPreview2", resizedImage);
        }

        gray.delete();

        if (this.state.debugMode) {
            let debugPreview = cv.Mat.zeros(resizedImage.cols, resizedImage.rows, cv.CV_8UC3);

            contoursArray.forEach((value, i) => {
                const rect = cv.boundingRect(contoursArray[i]);

                let color = new cv.Scalar(Math.round(Math.random() * 255), Math.round(Math.random() * 255), Math.round(Math.random() * 255));
                cv.drawContours(debugPreview, contours, i, color, 2, cv.LINE_8, hierarchy, 100);
                cv.putText(debugPreview, rect.width + "x" + rect.height, new cv.Point(rect.x, rect.y), cv.FONT_HERSHEY_COMPLEX, 1, new cv.Scalar(255, 255, 255));
            })

            cv.imshow("canvasPreview", debugPreview);
            debugPreview.delete();
        }

        let roi;

        for (let i = 0; i < contoursArray.length; i++) {
            if (roi) {
                break;
            }

            if (contoursArray.length < 1) {
                continue;
            }

            const rect = cv.boundingRect(contoursArray[i]);

            const aspectRatio = rect.width / rect.height;

            const validAspectRatio = aspectRatio >= 3.0 && aspectRatio <= 70;
            const roiWidthPercentage = 100.0 / resizedImage.cols * rect.width;

            if (!validAspectRatio || roiWidthPercentage < 60.0) {
                continue;
            }

            const pX = Math.floor((rect.x + rect.width) * 0.03), pY = Math.floor((rect.y + rect.height) * 0.03);

            rect.x = rect.x - pX;
            rect.y = rect.y - pY;
            rect.width = rect.width + (pX * 2);
            rect.height = rect.height + (pY * 2);

            if (rect.x < 0) {
                rect.x = 0;
            }

            if (rect.y < 0) {
                rect.y = 0;
            }

            if (rect.width < 0) {
                rect.width = 0;
            }

            if (rect.height < 0) {
                rect.height = 0;
            }

            const scaleMultiplier = originalImage.cols / resizedImage.cols;
            rect.x = rect.x * scaleMultiplier;
            rect.y = rect.y * scaleMultiplier;
            rect.width = rect.width * scaleMultiplier;
            rect.h = rect.height * scaleMultiplier;

            let point1 = new cv.Point(rect.x, rect.y);
            let point2 = new cv.Point(rect.x + rect.width, rect.y + rect.height);

            //draw the rect in the original image
            cv.rectangle(originalImage, point1, point2, validAspectRatio ? new cv.Scalar(0, 255, 0) : new cv.Scalar(255, 255, 255), 2, cv.LINE_AA, 0);

            const roiRect = this.intersectRect(rect, new cv.Rect(0, 0, originalImage.cols, originalImage.rows));

            roi = originalImage.roi(roiRect);
        }

        resizedImage.delete();
        contours.delete();
        hierarchy.delete();

        return roi;
    }


    private intersectRect = (a, b) => {
        const cv = (window as any).cv;

        const x1 = Math.max(a.x, b.x);
        const y1 = Math.max(a.y, b.y);

        a.width = Math.min(a.x + a.width, b.x + b.width) - x1;
        a.height = Math.min(a.y + a.height, b.y + b.height) - y1;

        a.x = x1;
        a.y = y1;

        if (a.width <= 0 || a.height <= 0) {
            a = new cv.Rect();
        }

        return a;
    }

    private parseMrz = (mrzValue: string): Promise<string> => {
        const id = Util.generateNewId();
        const deferredPromise = this.mrzParserDeferredPromiseExecutor.defer<any>(id);
        this.mrzParserWorker!.postMessage({id: id, cmd: "MRZ_PARSE", value: mrzValue});
        return deferredPromise;
    }

    private initializeMrzParserWorker = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            this.mrzParserWorker = new Worker("docscan/mrz_parser.js");

            const self = this;

            this.mrzParserWorker.onmessage = function (event) {
                switch (event.data.cmd) {
                    case "MRZ_PARSER_READY":
                        resolve();
                        break;
                    case "MRZ_PARSED":
                        if (!event.data.id) {
                            console.warn("received a message without an id!");
                            return;
                        }

                        if (event.data.value) {
                            self.mrzParserDeferredPromiseExecutor.resolve(event.data.id, event.data.value);
                        } else {
                            self.mrzParserDeferredPromiseExecutor.reject(event.data.id);
                        }
                        break;
                }
            };
        });
    }

    private initializeTesseractWorker = (): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            // @ts-ignore
            const {createWorker} = Tesseract;

            this.tesseractWorker = createWorker({
                langPath: "../docscan",
                workerPath: '../docscan/worker.min.js',
                corePath: '../docscan/tesseract-core.wasm.js',
                gzip: false,
                logger: m => console.log(m)
            });

            await this.tesseractWorker!.load();
            await this.tesseractWorker!.loadLanguage("mrz_fast");
            await this.tesseractWorker!.initialize("mrz_fast");
            await this.tesseractWorker!.setParameters({
                tessedit_char_whitelist: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789<',
                tessedit_pageseg_mode: "3",
                user_defined_dpi: "300",
                preserve_interword_spaces: "0",
                load_system_dawg: 0,
                load_freq_dawg: 0,
            });

            resolve();
        });
    }

    public render() {
        const {isReady} = this.state;

        return (
            <div>
                <div style={{
                    position: "fixed",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 500ms ease-in-out",
                    zIndex: 500000001,
                    opacity: this.state.scanResult ? 1 : 0,
                    background: "rgba(0,0,0,0.50)",
                }}>
                    <div className={"loader-container"}>
                        <div className="circle-loader load-complete">
                            <div className="checkmark draw"/>
                        </div>
                        <Typography variant={"subtitle1"} style={{whiteSpace: "nowrap"}}>
                            {this.state.scanResult?.given_name_0}
                            {" "}
                            {this.state.scanResult?.surname}
                            {" "}
                            {this.state.scanResult?.nationality}
                        </Typography>
                        <Typography variant={"caption"}>
                            {"Passport Number: " + this.state.scanResult?.doc_number}
                        </Typography>
                        <Typography variant={"caption"}>
                            {"Expiry Date: " + this.state.scanResult?.expiry_date}
                        </Typography>
                    </div>
                    <Button
                        style={{margin: 8, zIndex: 9999999999999}}
                        size={"small"}
                        onClick={() => {
                            this.setState({
                                scanResult: undefined
                            })
                        }} variant={"contained"} color={"primary"}>Continue</Button>
                </div>
                <div style={{
                    position: "fixed",
                    right: 16,
                    top: 16,
                    zIndex: 500000001
                }}>
                    <IconButton size={"medium"} onClick={event => {
                        this.setState({
                            debugMode: !this.state.debugMode
                        }, () => {
                            window.localStorage.setItem("debugMode", this.state.debugMode.toString());
                        })
                    }}>
                        <BugReportIcon/>
                    </IconButton>
                </div>
                <div style={{
                    position: "fixed",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 500ms ease-in-out",
                    zIndex: 500000000,
                    background: "black",
                    opacity: this.state.isReady ? 0 : 1
                }}>
                    <Typography align={"center"} variant={"button"}>{this.state.progressText}</Typography>
                    <CircularProgress style={{
                        position: "fixed",
                        bottom: 16
                    }}/>
                </div>
                <div style={{
                    overflow: "hidden",
                    position: "relative",
                    display: "grid",
                    height: "100%",
                    width: "100%",
                    animation: "fadein ease-in-out 1000ms"
                }}>
                    <canvas style={{display: "none"}} id="canvasRoi">

                    </canvas>
                    <canvas id={"canvasPreview"}
                            style={{
                                opacity: this.state.debugMode ? 0.5 : 0,
                                position: "fixed",
                                height: "25%",
                                top: 16,
                                left: 16,
                                borderRadius: 6,
                                zIndex: 500000000,
                                transition: "opacity 250ms ease-in-out",
                                border: "dashed 0.5px white"
                            }}>
                    </canvas>
                    <canvas id={"canvasPreview2"}
                            style={{
                                opacity: this.state.debugMode ? 0.5 : 0,
                                position: "fixed",
                                height: "25%",
                                bottom: 16,
                                left: 16,
                                borderRadius: 6,
                                zIndex: 500000000,
                                transition: "opacity 250ms ease-in-out",
                                border: "dashed 0.5px white"
                            }}>
                    </canvas>
                    <canvas id={"canvasPreview3"}
                            style={{
                                opacity: this.state.debugMode ? 0.5 : 0,
                                position: "fixed",
                                maxWidth: "40%",
                                bottom: 16,
                                right: 16,
                                borderRadius: 6,
                                zIndex: 500000000,
                                transition: "opacity 250ms ease-in-out",
                                border: "dashed 0.5px white"
                            }}>
                    </canvas>
                    <video autoPlay={true} id="camera_renderer"
                           controls={false}
                           muted={true}
                           playsInline
                           style={{
                               position: "fixed",
                               top: 0,
                               left: 0,
                               right: 0,
                               bottom: 0,
                               pointerEvents: "none",
                               height: "100%",
                               width: "100%",
                               filter: this.state.scanResult ? "blur(10px)" : undefined
                           }}/>
                </div>
                <div style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{
                        width: "98%",
                        height: "40%",
                        borderRadius: "10px",
                        border: "0.5px dashed white",
                        boxShadow: "0 0 0 9999px rgba(0, 0, 0, 1)"
                    }}>
                        {
                            <div style={{
                                opacity: !isReady ? 1 : 0,
                                transition: "all 500ms ease-in-out"
                            }}
                                 className={"loader-container"}>
                                <div className="circle-loader">
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
        )
    }

}
