import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import {SnackbarProvider} from "notistack";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {MuiThemeProvider} from "@material-ui/core";
import {appTheme} from "./Styles";
import PassportScanner from "./components/PassportScanner";
import Debug from "./components/Debug/Debug";

// https://codeburst.io/getting-started-with-react-router-5c978f70df91 (TODO: check url params)
// tslint:disable-next-line: no-empty-interface
interface IProps {

}

// tslint:disable-next-line: no-empty-interface
interface IState {

}

class AppRoutes extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        window.addEventListener("storage", () => {
            this.forceUpdate();
        });
    }

    public render() {
        return (
            <MuiThemeProvider theme={appTheme}>
                <SnackbarProvider>
                    <Router>
                        <Switch>
                            <Route exact path="/" component={App}/>
                            <Route exact path="/ds" component={PassportScanner}/>
                            <Route exact path="/debug" component={Debug}/>
                        </Switch>
                    </Router>
                </SnackbarProvider>
            </MuiThemeProvider>);
    }
}

ReactDOM.render(<AppRoutes/>, document.getElementById("root"));

serviceWorker.unregister();
